"use client";
import { useState, FC, useMemo, useCallback } from "react";
import { IPageBlock, ImageData } from "@/types/ui";
import styles from "./image-lead-form.module.scss";
import BlockImage from "@/ui/common-components/block-image";
import FormTextField from "@/ui/common-components/form-fields/text-field";
import { FormFieldValidator } from "@/utils/formFieldValidator";
import { AnalyticsPageType, sendAnalyticsEvent } from "@/utils/analitics";
import FormTextAreaField from "@/ui/common-components/form-fields/textarea-field";
import FormDropdownField from "@/ui/common-components/form-fields/dropdown-field";
import { ContactType, LeadData } from "@/types/lead";
import Button from "@/ui/common-components/button";
import { addContactLead } from "@/api/client-api";
import ContactPhoneField from "@/ui/common-components/form-fields/contact-phone";
import StringToHtmlTransformer from "@/ui/common-components/string-to-html-transformer";
import { getTranslationModule } from "../../../utils/translations";

interface IImageLeadFormProps {
  text?: string;
  image: ImageData;
  source: string;
  providerName: string;
  questionsTitle?: string;
  buttonTitle?: string;
}

interface IImageLeadData {
  firstName: string;
  lastName: string;
  contactType: ContactType;
  phone: string;
  comment: string;
}

const ImageLeadForm: FC<IPageBlock> = ({ config, siteData, pageContext, translations }) => {
  const t_common = getTranslationModule(translations, "common");
  const t_image_lead_form = getTranslationModule(translations, "image-lead-form");
  const defaultDealer = siteData.dealerInfos[0];
  const dealerId = defaultDealer?.id;
  const leadPopupConfig: IImageLeadFormProps = config;
  const [disabled, setDisabled] = useState(false);

  const [leadData] = useState<IImageLeadData>({
    firstName: "",
    lastName: "",
    contactType: ContactType.PHONE,
    phone: "",
    comment: "",
  });

  const [formValidator, setFormValidator] = useState(new FormFieldValidator(leadData));

  const formEventData = useMemo(() => {
    return {
      comm_type: "form",
      form_name: "image-lead-form",
      form_type: "consumer_contact",
      department: "sales",
      page_type: AnalyticsPageType.contact,
    };
  }, []);

  const contactOptions = useMemo(
    () => [
      {
        value: ContactType.MESSAGE,
        text: t_image_lead_form("text"),
      },
      { value: ContactType.PHONE, text: t_image_lead_form("phone") },
    ],
    [t_image_lead_form],
  );

  const submitHandler = useCallback(() => {
    if (formValidator.validate()) {
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: t_common("submit"),
        element_type: "form",
        event_action_result: "success",
        element_title: "submit",
        link_url: "",
      });
      sendAnalyticsEvent("asc_form_engagement", { comm_status: "crm_update", ...formEventData });
      setDisabled(true);

      const leadDataToSent: LeadData = {
        ...formValidator.formData,
        comment: `Question: ${formValidator.formData.comment}`,
        source: leadPopupConfig.source,
        providerName: leadPopupConfig.providerName,
        siteConfigId: siteData.siteConfigId,
        siteInstanceId: siteData.siteConfigId,
      };

      addContactLead(dealerId, leadDataToSent).then(() => {
        sendAnalyticsEvent("asc_form_submission", {
          ...formEventData,
        });

        sendAnalyticsEvent("asc_form_submission_sales", {
          ...formEventData,
        });

        setTimeout(() => {
          setFormValidator(
            new FormFieldValidator({
              firstName: "",
              lastName: "",
              contactType: ContactType.PHONE,
              phone: "",
              comment: "",
            }),
          );
          setDisabled(false);
        }, 7000);
      });
    } else {
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: t_common("submit"),
        element_type: "form",
        event_action_result: "error",
        element_title: "submit",
        link_url: "",
      });
    }
  }, [
    dealerId,
    formEventData,
    formValidator,
    leadPopupConfig.providerName,
    leadPopupConfig.source,
    siteData.siteConfigId,
    t_common,
  ]);

  return (
    <section className={styles.container}>
      {leadPopupConfig?.image?.url && (
        <BlockImage
          className={styles.image}
          image={leadPopupConfig.image}
          lazyLoading={false}
          mobile={pageContext.isSmartphone}
          siteData={siteData}
          alt={""}
        />
      )}
      <div className={styles.content}>
        {leadPopupConfig?.text && <StringToHtmlTransformer layout={leadPopupConfig.text} className={styles.text} />}
        <form className={styles.form}>
          <FormTextAreaField
            label={leadPopupConfig?.questionsTitle || t_image_lead_form("questions")}
            formValidator={formValidator}
            fieldName="comments"
            disabled={false}
            required={false}
            formEventData={formEventData}
          />
          <FormDropdownField
            label={t_image_lead_form("reply")}
            options={contactOptions}
            disabled={false}
            formValidator={formValidator}
            fieldName="contactType"
            required={true}
            errorMessage={t_common("select_an_option")}
            formEventData={formEventData}
          />
          <ContactPhoneField
            formValidator={formValidator}
            fieldName={"phone"}
            label={t_image_lead_form("reach")}
            disabled={false}
            formEventData={formEventData}
            translations={translations}
          />
          <FormTextField
            formValidator={formValidator}
            fieldName={"firstName"}
            label={t_common("first_name")}
            inputType="text"
            errorMessage={t_common("first_name_error")}
            required={true}
            disabled={false}
            maxLength={64}
            formEventData={formEventData}
          />
          <FormTextField
            formValidator={formValidator}
            fieldName={"lastName"}
            label={t_common("last_name")}
            inputType="text"
            errorMessage={t_common("last_name_error")}
            required={true}
            disabled={false}
            maxLength={64}
            formEventData={formEventData}
          />
          {disabled ? (
            <p>{t_image_lead_form("thank_you")}</p>
          ) : (
            <Button
              type={"primary"}
              ariaLabel={leadPopupConfig?.buttonTitle || t_image_lead_form("send")}
              text={leadPopupConfig?.buttonTitle || t_image_lead_form("send")}
              onClick={submitHandler}
              className={styles.button}
            />
          )}
        </form>
      </div>
    </section>
  );
};

export default ImageLeadForm;
